import { useEffect } from "react"
import type { AppProps } from "next/app"
import Head from "next/head"
import { useRouter } from "next/router"
import { RecoilRoot } from "recoil"
import { ThemeProvider } from "styled-components"
import ReactModal from "react-modal"
import { isProduction } from "@lib/frontend/isProduction"
import { pageview } from "@lib/frontend/ga"
import { theme } from "@styles/theme"
import "@styles/reset.css"
import "react-toastify/dist/ReactToastify.css"
import "react-lazy-load-image-component/src/effects/blur.css"
import "react-loading-skeleton/dist/skeleton.css"
import "animate.css/animate.min.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "@toast-ui/editor/dist/toastui-editor.css"
import "@styles/richtext.css"
import "@styles/globals.css"
import { SEO_CONFIG } from "@components/layout/default/Layout"

ReactModal.setAppElement("#__next")

export default function RootApp({ Component, pageProps }: AppProps) {
  const router = useRouter()

  useEffect(() => {
    if (isProduction() && typeof window.gtag !== "undefined") {
      const handleRouteChange = (url: URL) => {
        pageview(url)
      }
      router.events.on("routeChangeComplete", handleRouteChange)
      return () => {
        router.events.off("routeChangeComplete", handleRouteChange)
      }
    }
  }, [router.events])

  useEffect(() => {
    const MESSAGE = `
    _______    _____   ________      ______    ______    
    |_   __ \\  |_   _| |_   __  |    / ____ \`. |_   _ \`.  
      | |__) |   | |     | |_ \\_|    \`'  __) |   | | \`. \\ 
      |  ___/    | |     |  _| _     _  |__ '.   | |  | | 
     _| |_      _| |_   _| |__/ |   | \\____) |  _| |_.' / 
    |_____|    |_____| |________|    \\______.' |______.'  
  
    <Come_To_Join_Our_Developers recruiting={true} email={"pie@liostudio.co"} />
    `

    /* eslint-disable-next-line no-console */
    console.log(MESSAGE)
  }, [])

  return (
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <Head>
          <title>{SEO_CONFIG.title}</title>
          <meta name="description" content={SEO_CONFIG.description} />
          {/* seo */}
          <meta
            name="naver-site-verification"
            content="5675584a5e6916422721143a6480bf26d62a4133"
          />
          <meta
            name="facebook-domain-verification"
            content="6x5tnd2qmcjnqujxy7qbccc08zz1gc"
          />
          {/* 연관 채널 */}
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: `
              {
                "@context":"http://schema.org",
                "@type":"Organization",
                "name":"파이3D | 상상의 실현, 3D콘텐츠 에셋 스토어 - PIE3D",
                "url":"https://www.pie3d.com",
                "sameAs":[
                  "https://www.facebook.com/Pie3d.liostudio",
                  "https://www.instagram.com/pie3d_kr/",
                  "https://blog.naver.com/pie3d_kr"
                ]
              }`,
            }}
          />
          <link rel="canonical" href={SEO_CONFIG.ogUrl} />
          <noscript>자바스크립트를 활성화 해주시기 바랍니다.</noscript>
        </Head>
        {/* eslint-disable-next-line @next/next/no-sync-scripts */}
        <script
          src="https://t1.kakaocdn.net/kakao_js_sdk/2.1.0/kakao.min.js"
          integrity="sha384-dpu02ieKC6NUeKFoGMOKz6102CLEWi9+5RQjWSV0ikYSFFd8M3Wp2reIcquJOemx"
          crossOrigin="anonymous"
        ></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
                  Kakao.init('c8de8b3d2e67fc1449db941e8aac089b');
                  window.Kakao = Kakao;
                  (function() {
                    var w = window;
                    if (w.ChannelIO) {
                      return (window.console.error || window.console.log || function(){})('ChannelIO script included twice.');
                    }
                    var ch = function() {
                      ch.c(arguments);
                    };
                    ch.q = [];
                    ch.c = function(args) {
                      ch.q.push(args);
                    };
                    w.ChannelIO = ch;
                    function l() {
                      if (w.ChannelIOInitialized) {
                        return;
                      }
                      w.ChannelIOInitialized = true;
                      var s = document.createElement('script');
                      s.type = 'text/javascript';
                      s.async = true;
                      s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js';
                      s.charset = 'UTF-8';
                      var x = document.getElementsByTagName('script')[0];
                      x.parentNode.insertBefore(s, x);
                    }
                    if (document.readyState === 'complete') {
                      l();
                    } else if (window.attachEvent) {
                      window.attachEvent('onload', l);
                    } else {
                      window.addEventListener('DOMContentLoaded', l, false);
                      window.addEventListener('load', l, false);
                    }
                  })();
                  ChannelIO('boot', {
                    "pluginKey": "${process.env.NEXT_PUBLIC_CHANNEL_IO_KEY}"
                  });                 
                  `,
          }}
        />

        <img
          src="https://iplogger.com/1ZLVL4.png"
          alt=""
          style={{ display: "none" }}
        />
        <Component {...pageProps} />
      </ThemeProvider>
    </RecoilRoot>
  )
}
