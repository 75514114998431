import axios, { AxiosRequestConfig } from "axios"

export const getFetcher = async (url: string) => {
  const response = await axios.get(url)
  return response.data
}

export const postFetcher =
  <T>(data: T, config?: AxiosRequestConfig) =>
  async (url: string) => {
    const response = await axios.post(url, data, config)
    return response.data
  }
