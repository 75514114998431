import Image from "next/image"
import Link from "next/link"

type Props = {
  width?: number
  height?: number
  margin?: string
}

export default function PieLogo({
  width = 110,
  height = 32,
  margin = "0",
}: Props) {
  return (
    <div
      style={{
        margin,
        minWidth: width,
        height,
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <Link href="/" passHref>
        <a>
          <Image src="/logo.svg" alt="logo" width={width} height={height} />
        </a>
      </Link>
    </div>
  )
}
