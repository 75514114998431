import styled from "styled-components"

export const LayoutStyle = {
  Wrapper: styled.div`
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  `,
  Main: styled.main`
    margin-top: 0;
    width: 100%;
    margin-bottom: auto;
  `,
  MobileCTA: styled.div`
    position: fixed;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: 10px;
    width: 80%;
    height: 45px;
    z-index: 10000;
    background-color: #ff6600;
    color: white;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    cursor: pointer;

    @media screen and (min-width: 780px) {
      display: none;
      opacity: 0;
      z-index: -10000;
    }
  `,
}
