import { useRouter } from "next/router"
import { useState, useEffect } from "react"
import { toast } from "react-toastify"

/* eslint-disable @typescript-eslint/no-explicit-any */
export default function useSWRError(error: any, redirect?: string) {
  const router = useRouter()
  const [isFirst, setIsFirst] = useState(false)

  useEffect(() => {
    if (error && !isFirst) {
      setIsFirst(true)
      const message = error.response.data.message
      toast.error(message)
      if (redirect !== undefined) {
        router.push(redirect)
      }
    }
  }, [error, isFirst, router, redirect])
}
