import { APP_HOST, GOOGLE_CLIENT_ID, KAKAO_CLIENT_ID } from "./env"

export const AUTH_REDIRECT_URI = {
  KAKAO: `${APP_HOST}/auth/callback/kakao`,
  GOOGLE: `${APP_HOST}/auth/callback/google`,
}

export const PAYMENTS_REDIRECT_URI = {
  SUCCESS: `${APP_HOST}/api/orders/success`,
  FAIL: `${APP_HOST}/order/fail`,
}

export const REQUIRED_LOGIN = "로그인 후 사용해주세요."

export const LAST_URL_BEFORE_LOGIN_KEY = "LAST_URL_BEFORE_LOGIN_KEY"

export const GOOGLE_LOGIN_URL = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${GOOGLE_CLIENT_ID}&redirect_uri=${AUTH_REDIRECT_URI.GOOGLE}&response_type=token&scope=https://www.googleapis.com/auth/userinfo.email`
export const KAKAO_LOGIN_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${KAKAO_CLIENT_ID}&redirect_uri=${AUTH_REDIRECT_URI.KAKAO}&response_type=code`

// 개인정보처리방침
export const PRIVACY_POLICY =
  "https://liostudio.notion.site/99badd70d27346e3a5bf5bb149f43abb"

// 고객센터
export const CS =
  "https://liostudio.notion.site/PIE-003f8bbd32c141dba7728efd104df3d2"
