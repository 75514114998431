import { MutatingDots } from "react-loader-spinner"
import styled from "styled-components"

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  z-index: 100000;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
`

type Props = {
  message: string
}

export default function LoadingWrapper({ message }: Props) {
  return (
    <Wrapper>
      <style>{`*{ cursor: wait; } html,body{ overflow: hidden; height: 100%; }`}</style>
      <MutatingDots
        color="#ff8838"
        secondaryColor="#ff8838"
        height={100}
        width={100}
        visible
      />
      <span>{message}</span>
    </Wrapper>
  )
}
