import cuid from "cuid"
import { useCallback } from "react"
import { atom, useSetRecoilState } from "recoil"

const defaultMessage = ""

type Loading = {
  isLoading: boolean
  message: string
}

export const loadingState = atom<Loading>({
  key: cuid(),
  default: { isLoading: false, message: "" },
})

export function useLoadingAction() {
  const set = useSetRecoilState(loadingState)

  const startLoading = useCallback(
    (message = defaultMessage) => {
      set({ message, isLoading: true })
    },
    [set],
  )

  const finishLoading = useCallback(() => {
    set({ message: "", isLoading: false })
  }, [set])

  return { startLoading, finishLoading }
}

type MouseLoading = boolean

export const mouseLoadingState = atom<MouseLoading>({
  key: "mouseLoadingState",
  default: false,
})

export function useMouseLoadingAction() {
  const set = useSetRecoilState(mouseLoadingState)

  const startLoading = useCallback(() => {
    set(true)
  }, [set])

  const finishLoading = useCallback(() => {
    set(false)
  }, [set])

  return { startLoading, finishLoading }
}
