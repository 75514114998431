import styled from "styled-components"
import { windowOpener } from "@lib/frontend/windowOpener"
import { CS, PRIVACY_POLICY } from "@lib/constants"
import PieLogo from "@components/share/PieLogo"

const BUYER_GUIDE =
    "https://liostudio.notion.site/PIE-c65e6a9974aa4a6e817ed1f0f7631522",
  CREATOR_GUIDE =
    "https://liostudio.notion.site/PIE-7fa52b77f48d42ffbe990f3afc75c798",
  FACEBOOK = "https://www.facebook.com/Pie3d.liostudio",
  TWITTER = "https://twitter.com/pie3d_kr",
  INSTAGRAM = "https://www.instagram.com/pie3d_kr/",
  BLOG = "https://blog.naver.com/pie3d_kr",
  PIE3D_SERVICE =
    "https://static.pie3d.com/web/PIE3D_ServiceIntroduction_042023.pdf"

const companyName = "(주) 하이퍼이지",
  information = [
    "대표 | 윤상석, 윤예림",
    "support@pie3d.com",
    "사업자번호 | 627-87-03026",
    // "통신판매업신고번호 | 2022-의정부호원-0358",
    "주소 | 경기도 성남시 수정구 대왕판교로 815, 판교창조경제벨리, 메타버스허브 409호",
    "전화번호",
    "🇰🇷 | 070 4201 5800",
    "🇺🇸 | (626) 8001 PIE",
  ],
  copyright = "© 2023"

const Style = {
  Wrapper: styled.div`
    background-color: #e6e6e8;
    color: black;
    width: 100%;
    height: fit-content;
    text-align: left;
  `,
  InnerWrapper: styled.div<{ isFix1200px: boolean }>`
    background-color: #e6e6e8;
    width: ${({ isFix1200px }) => (isFix1200px ? "1200px" : `100%`)};
    ${({ isFix1200px }) => isFix1200px && `margin: 0 auto;`};
  `,
  ContentBlock: styled.div`
    display: flex;
    gap: 196px;
    padding: 20px 103px;
    height: 283px;
    border-bottom: 1px solid black;
    @media screen and (max-width: 820px) {
      gap: 40px;
      padding: 20px 36px;
    }
  `,
  Title: styled.div`
    font-size: 15px;
    font-weight: 500;
    line-height: 19px;
    margin-bottom: 35px;
    cursor: pointer;
    // ::selection {
    //   background-color: #00fc00;
    //   color: #000000;
    // }
  `,
  ContentItem: styled.div`
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    margin-bottom: 12px;
    cursor: pointer;
    // ::selection {
    //   background-color: #00fc00;
    //   color: #000000;
    // }
    :hover {
      text-decoration: underline;
    }
  `,

  InfoBlock: styled.div`
    display: flex;
    gap: 50px;
    padding: 20px 103px;
    border-bottom: 1px solid black;
    @media screen and (max-width: 820px) {
      gap: 50px;
      padding: 20px 36px;
    }
  `,
  InfoItem: styled.div`
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    margin-top: 4px;
    margin-bottom: 4px;
    // ::selection {
    //   background-color: #00fc00;
    //   color: #000000;
    // }
  `,

  CopyrightBlock: styled.div`
    display: flex;
    justify-content: center;
    gap: 12px;
    padding: 17px 0;
    border-bottom: 1px solid black;
  `,
  Copyright: styled.div``,
}

export default function Footer({
  isFix1200px = false,
}: {
  isFix1200px?: boolean
}) {
  return (
    <Style.Wrapper>
      <Style.InnerWrapper isFix1200px={isFix1200px}>
        <Style.ContentBlock>
          <div>
            <Style.Title>About Us</Style.Title>
            <Style.ContentItem
              onClick={() => windowOpener("https://hyperez.io")}
            >
              회사소개
            </Style.ContentItem>
            <Style.ContentItem onClick={() => windowOpener(PIE3D_SERVICE)}>
              서비스소개서
            </Style.ContentItem>
          </div>
          <div>
            <Style.Title>Service</Style.Title>
            <Style.ContentItem
              onClick={() => windowOpener("https://hyperez.io")}
            >
              제작 의뢰하기
            </Style.ContentItem>
            <Style.ContentItem onClick={() => windowOpener(BUYER_GUIDE)}>
              구매자 가이드
            </Style.ContentItem>
            <Style.ContentItem onClick={() => windowOpener(CREATOR_GUIDE)}>
              판매자 가이드
            </Style.ContentItem>
            <Style.ContentItem onClick={() => windowOpener(CS)}>
              고객 센터
            </Style.ContentItem>
            <Style.ContentItem
              onClick={() => windowOpener("https://www.pie3d.com/termsofuse")}
            >
              서비스 이용약관
            </Style.ContentItem>
            <Style.ContentItem onClick={() => windowOpener(PRIVACY_POLICY)}>
              개인정보처리방침
            </Style.ContentItem>
          </div>
          <div>
            <Style.Title>Social</Style.Title>
            <Style.ContentItem onClick={() => windowOpener(INSTAGRAM)}>
              INSTAGRAM
            </Style.ContentItem>
            <Style.ContentItem onClick={() => windowOpener(TWITTER)}>
              TWITTER
            </Style.ContentItem>
            <Style.ContentItem onClick={() => windowOpener(FACEBOOK)}>
              FACEBOOK
            </Style.ContentItem>
            <Style.ContentItem onClick={() => windowOpener(BLOG)}>
              BLOG
            </Style.ContentItem>
          </div>
        </Style.ContentBlock>

        <Style.InfoBlock>
          <div>
            <Style.InfoItem>{companyName}</Style.InfoItem>
            <Style.InfoItem>{information[0]}</Style.InfoItem>
            <Style.InfoItem>{information[1]}</Style.InfoItem>
          </div>
          <div>
            <Style.InfoItem>{information[2]}</Style.InfoItem>
            <Style.InfoItem>{information[3]}</Style.InfoItem>
            <Style.InfoItem>{information[4]}</Style.InfoItem>
            <Style.InfoItem>{information[5]}</Style.InfoItem>
            <Style.InfoItem>{information[6]}</Style.InfoItem>
            <Style.InfoItem>{information[7]}</Style.InfoItem>
          </div>
        </Style.InfoBlock>

        <Style.CopyrightBlock>
          <Style.Copyright>{copyright}</Style.Copyright>
          <PieLogo width={50} height={14.6} />
        </Style.CopyrightBlock>
      </Style.InnerWrapper>
    </Style.Wrapper>
  )
}
