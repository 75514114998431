import { getFetcher } from "@lib/frontend/swr"
import { AuthUser } from "@pages/api/auth"
import useSWR from "swr"
import useSWRError from "./useSWRError"

type IsLoading = {
  isLoading: true
}

type IsDone = {
  isLoading: false
  data: AuthUser["user"]
}

export const GET_USER_SESSION_KEY = "PIE_SESSION_USER"

export type CurrentUser = IsLoading | IsDone

export default function useCurrentUser(): CurrentUser {
  const { data, error } = useSWR<AuthUser>("/api/auth", getFetcher)
  useSWRError(error)
  return data === undefined
    ? { isLoading: true }
    : { isLoading: false, data: data.user }
}
