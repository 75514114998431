import { ReactNode } from "react"
import { useRecoilValue } from "recoil"
import { ToastContainer } from "react-toastify"
import { LayoutStyle as Style } from "./style"
import { loadingState, mouseLoadingState } from "@store/loading"
import LoadingWrapper from "@components/share/LoadingWrapper"
import Footer from "@components/layout/default/Footer"
import useCurrentUser from "@hooks/useCurrentUser"
import dynamic from "next/dynamic"
import Head from "next/head"

const Header = dynamic(import("@components/layout/default/Header"), {
  ssr: false,
})

type Props = {
  children: ReactNode
  noShowCTA?: boolean
  noMetaTag?: boolean
  isFix1200px?: boolean
  style?: React.CSSProperties
}

export const SEO_CONFIG = {
  title: "파이3D | 상상의 실현, 3D에셋, 3D모델 스토어 - PIE3D",
  description:
    "국내 3D 크리에이터의 3D 모델링이 필요하신가요? 다양한 컨셉의 3D 모델링으로 표현의 한계를 없애고 팩 단위의 모델링으로 보다 합리적인 가격으로 사용하세요.",
  keywords:
    "3d에셋, 3d모델, 파이3d, 3d 모델링, 3d에셋 스토어, 에셋, pie 3d, pie3d",
  ogImage: "https://www.pie3d.com/kakao-800x400.jpg",
  ogUrl: "https://www.pie3d.com",
}

export default function Layout({
  children,
  noShowCTA = false,
  noMetaTag = false,
  isFix1200px = false,
  style = {},
}: Props): JSX.Element {
  const user = useCurrentUser()
  const { isLoading, message } = useRecoilValue(loadingState)
  const isMouseLoading = useRecoilValue(mouseLoadingState)

  return (
    <>
      <Head>
        {!noMetaTag && (
          <>
            <meta name="description" content={SEO_CONFIG.description} />
            <meta name="keywords" content={SEO_CONFIG.keywords} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={SEO_CONFIG.title} />
            <meta property="og:description" content={SEO_CONFIG.description} />
            <meta property="og:image" content={SEO_CONFIG.ogImage} />
            <meta property="og:url" content={SEO_CONFIG.ogUrl} />
          </>
        )}
      </Head>
      <Style.Wrapper style={style}>
        <style>{`html,body{ background-color: #f5f5f5; color: black; }`}</style>
        {isLoading && <LoadingWrapper message={message} />}
        {isMouseLoading && (
          <style>{`html,body{ cursor: wait !important; }`}</style>
        )}
        <ToastContainer theme="dark" />

        <Header user={user} isFix1200px={isFix1200px} />
        <Style.Main>{children}</Style.Main>
        <Footer isFix1200px={isFix1200px} />

        {/* 맨 밑에 이벤트성으로 띄우는 CTA */}
        {/* {!noShowCTA && (
          <>
            {!user.isLoading && !user.data && (
              <Style.MobileCTA
                onClick={() => {
                  setClickLoading(true)
                  router.push(`/auth/signup?last_url=/p/search`)
                }}
              >
                {clickLoading ? <BtnLoading /> : `지금 가입하고 쿠폰받기 >`}
              </Style.MobileCTA>
            )}
            {!user.isLoading && user.data && !user.data.isCreator && (
              <Style.MobileCTA
                onClick={() => {
                  windowOpener("https://creator.pie3d.com")
                }}
              >
                {clickLoading ? (
                  <BtnLoading />
                ) : (
                  `3D 크리에이터이신가요? 판매 시작하기 >`
                )}
              </Style.MobileCTA>
            )}
          </>
        )} */}
      </Style.Wrapper>
    </>
  )
}
