import { DefaultTheme } from "styled-components"

export const theme: DefaultTheme = {
  color: {
    black: "#000000", // main color

    // Brand_RED
    red50: "#ff6600",
    red60: "#ff6600",
    red70: "#ff6600",
    red80: "#ff6600",
    red90: "#ff6600",
    red95: "#ff6600",
    red100: "#ff6600", // main color

    // Grey Scale
    light: "#ffffff",
    gray10: "#fbfbfb",
    gray20: "#fcfcfc",
    gray30: "#f4f4f4",
    gray40: "#eeeeee",
    gray50: "#d3d3d3",
    gray55: "#a2a2a2",
    gray60: "#707070",
    gray70: "#565656",
    gray80: "#434343",
    gray90: "#303030",
    gray95: "#181818",
    gray100: "#1a1a1a",
    dark: "#080808",

    // semantic color
    green0: "#2e3224",
    green10: "#374023",
    green15: "#556c21",
    green20: "#daf197",
    green30: "#97cc00",
    green50: "#dbff00",
    green60: "#edf8cd",
    green100: "#00fc00",

    // sub color
    information: "#FF1E87",
    success: "#F7FE1E",
    error: "#00FC00",
    orange: "#FF961E",
  },
  shadow: {
    float1: "",
    float2: "",
    basic: "",
    line: "",
  },
}
